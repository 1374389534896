import { Box, Container, CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CoinIcon } from '../assets/icons';
import { useScore } from '../contexts';

const MainLayoutWrapper = styled(Box)({
  flexDirection: 'column',
  minHeight: '100vh',
  display: 'flex',
});

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const {score} = useScore(); // replace with actual user score

  return (
    <MainLayoutWrapper>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            504 German
          </Typography>
          <Typography mr={1} variant="subtitle2" component="div">
            {score}
          </Typography>
          <CoinIcon />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ flexGrow: 1 }}>
        {children}
      </Container>
    </MainLayoutWrapper>
  );
};

export default MainLayout;
