import { NUM_BOXES, PROGRESS_KEY } from './constants';
import { Word } from './types';
import { getRandomArrayItem } from 'services/array';

const localStorageWords = localStorage.getItem(PROGRESS_KEY);
let progress: Word[][] = localStorageWords ? JSON.parse(localStorageWords) : [[], [], [], [], []];
export const initializeProgress = (allWords: Word[]) => {
  // Add the words to the first box of the Leitner system
  if (!localStorage.getItem(PROGRESS_KEY)) {
    const initialProgress = Array(NUM_BOXES).fill([]);
    initialProgress[0] = allWords;
    localStorage.setItem(PROGRESS_KEY, JSON.stringify(initialProgress));
    progress = JSON.parse(localStorage.getItem(PROGRESS_KEY) || '[]');
  }
};

// Define a function to get the next word for the user to study
export function getNextWord(): Word | null {
  if (!progress) {
    return null;
  }  // Iterate over the boxes in the Leitner system, starting with the first box
  for (let box = 0; box < NUM_BOXES; box++) {
    // If there are words in the current box, return the first word
    if (progress[box].length > 0) {
      const randomWord = getRandomArrayItem(progress[box]);
      randomWord.box = box;
      return randomWord;
    }
  }

  // If there are no words in any of the boxes, return null
  return null;
}

// Define a function to move a word to the next box in the Leitner system
export function moveWordToNextBox(word: Word): void {
  // Find the current box that the word is in
  let currentBox = -1;
  for (let box = 0; box < NUM_BOXES; box++) {
    if (progress[box].includes(word)) {
      currentBox = box;
      break;
    }
  }

  // If the word is not in any box, return
  if (currentBox === -1) {
    return;
  }

  // If the word is in the last box, remove it from progress
  if (currentBox === NUM_BOXES - 1) {
    progress[currentBox] = progress[currentBox].filter((w: Word) => w !== word);
    localStorage.setItem(PROGRESS_KEY, JSON.stringify(progress));
    return;
  }

  // Move the word to the next box
  progress[currentBox] = progress[currentBox].filter((w: Word) => w !== word);
  progress[currentBox + 1].push(word);
  localStorage.setItem(PROGRESS_KEY, JSON.stringify(progress));
}

// Define a function to move a word to the first box in the Leitner system
export function moveWordToFirstBox(word: Word): void {
  // Find the current box that the word is in
  let currentBox = -1;
  for (let box = 0; box < NUM_BOXES; box++) {
    if (progress[box].includes(word)) {
      currentBox = box;
      break;
    }
  }

  // If the word is not in any box, add it to the first box
  if (currentBox === -1) {
    progress[0].push(word);
    localStorage.setItem(PROGRESS_KEY, JSON.stringify(progress));
    return;
  }

  // Move the word to the first box
  progress[currentBox] = progress[currentBox].filter((w: Word) => w !== word);
  progress[0].push(word);
  localStorage.setItem(PROGRESS_KEY, JSON.stringify(progress));
}

export function calculateScore(): number {

  let score = 0;
  for (let box = 1; box < NUM_BOXES; box++) {
    score += progress[box].length * (box + 1);
  }
  return score;
}
