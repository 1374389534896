import { useQuery } from '@tanstack/react-query';

export const useGetWords = () => {
  return useQuery({
    queryKey: ['words'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await fetch(`/words/words.json`);
      return await response.json();
    },
  });
};
