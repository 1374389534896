import { Box, Button, Card, CardActions, CardContent, IconButton, SvgIcon, Typography } from '@mui/material';
import { Word } from '../services/leitner/types';
import useConfig from 'contexts/config/useConfig';
import CampaignIcon from '@mui/icons-material/Campaign';
import {DEFlag, USFlag} from 'assets/icons';

type FlashCardProps = {
  word: Word;
  isFlipped: boolean;
  onFlip?: () => void;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const FlashCard = ({ word, isFlipped, onFlip, style, onClick }: FlashCardProps) => {
  const {landscape} = useConfig();
  const handlePronounce = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let utterance = new SpeechSynthesisUtterance();
    utterance.text = word[isFlipped ? 'english' : 'german'];
    utterance.lang = isFlipped ? 'en-US' : 'de-DE';
    window.speechSynthesis.speak(utterance);
  };
  return (
    <Card elevation={5} onClick={onClick} style={style}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          German word #{word.index} from box #{word.box}
        </Typography>
        <Typography variant={landscape ? "h2" : "h5"} component="div" textAlign="center">
        <SvgIcon sx={{verticalAlign: 'middle'}}>
          {isFlipped ? <USFlag /> : <DEFlag />}
        </SvgIcon> {isFlipped ? word.english : word.german}
        </Typography>
        
      </CardContent>
      <CardActions>

      <Button onClick={onFlip}>{isFlipped ? 'Show Front' : 'Show Back'}</Button>
      <Box sx={{marginLeft: "auto"}}>
        <IconButton onClick={handlePronounce}><CampaignIcon /></IconButton>
      </Box>
      </CardActions>
    </Card>
  );
};

export default FlashCard;
