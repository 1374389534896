
import React, { useRef, useState } from 'react';

type SwipableProps = {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
};

const Swipable: React.FC<React.PropsWithChildren<SwipableProps>> = ({ onSwipeLeft, onSwipeRight, children }) => {
  const [startX, setStartX] = useState<number | null>(null);
  const touchRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (startX === null) {
      return;
    }

    const endX = event.changedTouches[0].clientX;
    const deltaX = endX - startX;

    if (Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        onSwipeRight();
      } else {
        onSwipeLeft();
      }
    }

    setStartX(null);
  };

  return (
    <div ref={touchRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={(event) => event.stopPropagation()}>
      {children}
    </div>
  );
};

export default Swipable;

// const Swipable: React.FC<React.PropsWithChildren<SwipableProps>> = ({ onSwipeLeft, onSwipeRight, children }) => {
//   const [startX, setStartX] = useState<number | null>(null);
//   const touchRef = useRef<HTMLDivElement>(null);

//   const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
//     setStartX(event.touches[0].clientX);
//   };

//   const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
//     if (startX === null) {
//       return;
//     }

//     const endX = event.changedTouches[0].clientX;
//     const deltaX = endX - startX;

//     if (deltaX > 0) {
//       onSwipeRight();
//     } else {
//       onSwipeLeft();
//     }

//     setStartX(null);
//   };

//   return (
//     <div ref={touchRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
//       {children}
//     </div>
//   );
// };

// export default Swipable;
