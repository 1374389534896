import React, { createContext, useEffect, useReducer } from 'react';

type ConfigContextType = {
  landscape: boolean;
  dispatch: React.Dispatch<ConfigAction>;
};

type ConfigAction = { type: 'SET_LANDSCAPE'; payload: boolean };

const initialState = { landscape: window.matchMedia("(orientation: landscape)").matches };

const configReducer = (state: typeof initialState, action: ConfigAction) => {
  switch (action.type) {
    case 'SET_LANDSCAPE':
      return { ...state, landscape: action.payload };
    default:
      return state;
  }
};

export const ConfigContext = createContext<ConfigContextType>({
  landscape: false,
  dispatch: () => {},
});

type ConfigProviderProps = {
  children: React.ReactNode;
};

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [state, dispatch] = useReducer(configReducer, initialState);

  useEffect(() => {
    window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
      const portrait = e.matches;
  
      if (portrait) {
          dispatch({ type: 'SET_LANDSCAPE', payload: false });
      } else {
        dispatch({ type: 'SET_LANDSCAPE', payload: true });
      }
  });
  }, []);

  return (
    <ConfigContext.Provider value={{ landscape: state.landscape, dispatch }}>
      {children}
    </ConfigContext.Provider>
  );
};
