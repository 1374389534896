import React, { createContext, useState } from 'react';
import { calculateScore } from 'services/leitner/leitner';

interface ScoreContextType {
  score: number;
  updateScore: (newScore: number) => void;
}

export const ScoreContext = createContext<ScoreContextType>({
  score: 0,
  updateScore: () => {},
});
interface ScoreProviderProps {
  children: React.ReactNode;
}

export const ScoreProvider: React.FC<ScoreProviderProps> = ({ children }) => {
  const [score, setScore] = useState(calculateScore());

  const updateScore = (newScore: number) => {
    setScore(newScore);
  };

  return (
    <ScoreContext.Provider value={{ score, updateScore }}>
      {children}
    </ScoreContext.Provider>
  );
};
