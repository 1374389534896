import { createTheme } from '@mui/material/styles';
import { teal, indigo, amber } from '@mui/material/colors';


export const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    success: {
      main: teal[500],
    },
    warning: {
      main: amber[500],
    }
  },
});
