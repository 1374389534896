import { Box, Button } from "@mui/material";
import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from "react";
import FlashCard from "./components/FlashCard";
import MainLayout from "./components/MainLayout";
import Swipable from "./components/swipable/Swipable";
import { calculateScore, getNextWord, initializeProgress, moveWordToFirstBox, moveWordToNextBox } from "./services/leitner/leitner";
import { useScore } from "./contexts";
import { useGetWords } from "services/words/useGetWords";
import { Word } from "services/leitner/types";


const AnimatedBox = animated(Box);
const AnimatedButton = animated(Button);

export const App = () => {
  const {data: allWords} = useGetWords();
  const [word, setWord] = useState<Word | null>(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const {updateScore} = useScore();
  const [springs, cardApi] = useSpring(() => ({
    from: { x: 0, /*backgroundColor: 'white', */opacity: 1, },
    config: {duration: 300},
  }));
  const [wrongButtonSprings, wrongButtonApi] = useSpring(() => ({
    opacity: 1,
    from: { opacity: 1 },
    to: { opacity: 1 },
    reset: true,
    reverse: true,
    loop: { reverse: true, times: 1 }
  }));
  const [correctButtonSprings, correctButtonApi] = useSpring(() => ({
    opacity: 1,
    from: { opacity: 1 },
    to: { opacity: 1 },
    reset: true,
    reverse: true,
    loop: { reverse: true, times: 1 }
  }));

  const handleCorrectGuess = () => {
    if (!word) return;
    correctButtonApi.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
    });
    cardApi.start({
      from: {x: 0, opacity: 1/*, backgroundColor: 'white'*/},
      to: {x: 200, opacity: 0/*, backgroundColor: theme.palette.success.main*/},
      onRest: () => {
        moveWordToNextBox(word);
        setWord(() => getNextWord());
        setIsFlipped(false);
        updateScore(calculateScore());
        cardApi.set({x: 0, opacity: 0/*, backgroundColor: 'white'*/});
        cardApi.start({from: {opacity: 0}, to: {opacity: 1}})
      }
    })
  }
  const handleWrongGuess = () => {
    if (!word) return;
    wrongButtonApi.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
    });
    cardApi.start({
      from: {x: 0, opacity: 1,/* backgroundColor: 'white'*/},
      to: {x: -200, opacity: 0, /*backgroundColor: theme.palette.warning.main*/},
      onRest: () => {
        moveWordToFirstBox(word);
        setWord(getNextWord());
        setIsFlipped(false);
        updateScore(calculateScore());
        cardApi.set({x: 0, opacity: 0 /*, backgroundColor: 'white'*/});
        cardApi.start({from: {opacity: 0}, to: {opacity: 1}})
      }
    })
  }

  useEffect(() => {
    if (!allWords) return;
    initializeProgress(allWords);
    setWord(getNextWord());
  }, [allWords])

  return (
    <MainLayout>
      <h1>German 504!</h1>
      <AnimatedBox mb={2} style={springs}>
          <Swipable onSwipeLeft={handleWrongGuess} onSwipeRight={handleCorrectGuess}>
          {word
            ? <FlashCard word={word} isFlipped={isFlipped} onClick={() => setIsFlipped(was =>!was)} />
            : "no words in any of the boxes"
          }
          </Swipable>
      </AnimatedBox>
      <Box sx={{display: 'flex', position: 'fixed', bottom: 10, left: 0, width: '100%', px: 1}}>
        <AnimatedButton size="large" color="warning" style={wrongButtonSprings} fullWidth onClick={handleWrongGuess}>Wrong</AnimatedButton>
        <AnimatedButton size="small" style={correctButtonSprings} sx={{lineHeight: 4.5}} variant="contained" fullWidth color="success" onClick={handleCorrectGuess}>Correct</AnimatedButton>
      </Box>
    </MainLayout>
  )
    };

